import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import Slider from "react-slick";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { StackX, StackItem } from "../../../components/LayoutX/StackLayout";
import { Button, Card, Divider, IconButton } from "ui-neumorphism";
import ContainerX from "../../../components/general/ContainerX";
import { RowGrid } from "../../../components/LayoutX/Grid/RowGrid";
import BookingInfoIcon from "../../../assets/images/icon/booking.png";
import WorkProgressIcon from "../../../assets/images/icon/work-progress.png";
import LoanIcon from "../../../assets/images/icon/account.png";
import SnPIcon from "../../../assets/images/icon/s&p.png";
import KeyCollectionIcon from "../../../assets/images/icon/key-collection.png";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  SalesAccountQuery,
  useSalesAccountLazyQuery,
  useSalesAccountQuery,
  useGetSubMenuDetailsLazyQuery,
} from "../../../generated/graphql";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React, { useEffect, useState } from "react";
import {
  getSwiperSalesIdCookie,
  setSwiperSalesIdCookie,
} from "../../../containers/helpers/salesIdCookie";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { DASHBOARDS_PATH } from "./DashboardRoutes";
import TextX from "../../../components/general/TextX";
import Expanded from "../../../components/LayoutX/Expanded";
import { getPathCookie } from "../../../containers/helpers/PathCookie";
import SimpleDialog from "components/Dialog/SimpleDialog";
import KeyCollectionDialog from "./KeyCollection/KeyCollectionDialog";
import { textChangeColor } from "../../../containers/context/ContainerXContext";
import { cardChangeColor } from "../../../containers/context/CardContext";
import { useContext } from "react";
type UnitDetailsSwiperProps = {
  salesAccount?: SalesAccountQuery;
};

const UnitDetailsSwiper: React.FC<UnitDetailsSwiperProps> = ({
  salesAccount,
}) => {
  const [subMenu, setSubMenu] = useState<any>(null);

  const navigate = useNavigate();
  const [isOpenKeyCollectionDialog, setIsOpenKeyCollectionDialog] =
    useState(false);
  const [selectedAvailableDate, setSelectedAvailableDate] =
    useState<string>(null);
  const [selectedAvailableTime, setSelectedAvailableTime] =
    useState<string>(null);

  const [keyCollectionEmailTemplate, setKeyCollectionEmailTemplate] =
    useState<string>(null);

  const [currentSalesId, setCurrentSalesId] = useState(
    getSwiperSalesIdCookie() &&
      salesAccount?.getSalesAccount.find(
        (item) => item.SaleID === getSwiperSalesIdCookie()
      )
      ? getSwiperSalesIdCookie()
      : salesAccount?.getSalesAccount[0]?.SaleID
  );
  const [currentSlide, setCurrentSlide] =
    useState<(typeof salesAccount.getSalesAccount)[0]>(null);
  const [latestKeyCollectionDate, setLatestKeyCollectionDate] =
    useState<string>(null);

  const handleSetCurrentSlideSalesId = (id: string) => {
    setCurrentSalesId(id);
  };
  const { cardColor } = cardChangeColor();
  const { textColor } = textChangeColor();

  useEffect(() => {
    if (currentSalesId) {
      setCurrentSlide(
        salesAccount.getSalesAccount.find(
          (item) => item.SaleID === currentSalesId
        )
      );
    }
  }, [currentSalesId]);

  const handleJumpToBookingDetails = () => {
    navigate(
      `${DASHBOARDS_PATH.BOOKING_DETAILS.replace(
        ":accountCode",
        getPathCookie()
      )}?unitId=${currentSlide?.UnitID}&saleId=${
        currentSlide?.SaleID
      }&projectId=${currentSlide?.ProjectID}&isIntegrate=${
        currentSlide?.IsIntegrate
      }`
    );
  };

  const handleJumpToSiteProgress = () => {
    navigate(
      `${DASHBOARDS_PATH.SITE_PROGRESS.replace(
        ":accountCode",
        getPathCookie()
      )}?unitId=${currentSlide?.UnitID}&projectId=${
        currentSlide?.ProjectID
      }&isIntegrate=${currentSlide?.IsIntegrate}`
    );
  };

  const handleJumpToLoanListing = () => {
    navigate(
      `${DASHBOARDS_PATH.LOAN_LISTING.replace(
        ":accountCode",
        getPathCookie()
      )}?saleId=${currentSlide?.SaleID}&projectId=${currentSlide?.ProjectID}`
    );
  };

  const handleJumpToAccountState = () => {
    navigate(
      `${DASHBOARDS_PATH.ACCOUNT_STATE.replace(
        ":accountCode",
        getPathCookie()
      )}?unitId=${currentSlide?.UnitID}&saleId=${
        currentSlide?.SaleID
      }&projectId=${currentSlide?.ProjectID}&isIntegrate=${
        currentSlide?.IsIntegrate
      }`
    );
  };
  const [getSubMenu, { data: getSubMenuData }] = useGetSubMenuDetailsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.getSubMenuDetails) setSubMenu(data.getSubMenuDetails);
    },
  });

  const [fetchLatestSalesAccount] = useSalesAccountLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.getSalesAccount) {
        let latestData = data.getSalesAccount?.find(
          (item) =>
            item.UnitID === currentSlide?.UnitID &&
            item.SaleID == currentSlide?.SaleID
        );
        setLatestKeyCollectionDate(latestData?.KeyCollectionDate);
      }
    },
  });
  const { data: { getSalesAccount } = { getSalesAccount: null } } =
    useSalesAccountQuery({
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (getSalesAccount) {
      fetchLatestSalesAccount();
    }
  }, [getSalesAccount]);

  useEffect(() => {
    if (currentSlide?.ProjectID) {
      getSubMenu({
        variables: {
          projectId: currentSlide.ProjectID,
        },
      });
    }
  }, [currentSlide]);

  useEffect(() => {
    if (currentSlide?.KeyCollectionDate == null) {
      setKeyCollectionEmailTemplate("Key Collection Appointment");
    } else {
      setKeyCollectionEmailTemplate("Key Collection Appointment Rescheduling");
    }
  }, [currentSlide]);

  return (
    <>
      <Card elevation={2} style={{ backgroundColor: cardColor }}>
        <ColumnFlex>
          <div
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              paddingBottom: "30px",
            }}
          >
            <UnitDetailsSlider
              salesAccount={salesAccount}
              currentSalesId={currentSalesId}
              setCurrentSalesId={handleSetCurrentSlideSalesId}
            />
          </div>
          <ContainerX className="disable-user-selection">
            <RowGrid>
              {currentSlide?.SaleStatus === "Signed" &&
                subMenu?.signed?.s_and_p && (
                  <Button
                    className={"p-10 custom-neu-button-style"}
                    onClick={handleJumpToBookingDetails}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      height: "100%",
                    }}
                    text
                  >
                    <ColumnFlex crossAxisAlignment="center">
                      <img src={SnPIcon} className="disable-user-selection" />
                      <span className="fs-10 fw-600">S&P</span>
                      <TextX
                        dataType="Date"
                        data={currentSlide?.SignDate}
                        className="fs-8 fw-600"
                      />
                    </ColumnFlex>
                  </Button>
                )}

              {currentSlide?.SaleStatus === "Booked" &&
                subMenu?.booked?.booking_info && (
                  <Button
                    className={"p-10 custom-neu-button-style"}
                    onClick={handleJumpToBookingDetails}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      height: "100%",
                    }}
                    text
                  >
                    <ColumnFlex crossAxisAlignment="center">
                      <img src={SnPIcon} />
                      <span className="fs-10 fw-600">Booking Info</span>
                      <TextX
                        dataType="Date"
                        data={currentSlide?.BookDate}
                        className="fs-8 fw-600"
                      />
                    </ColumnFlex>
                  </Button>
                )}

              {(!!subMenu?.booked?.overall_site_progress &&
                currentSlide?.SaleStatus === "Booked") !==
                (!!subMenu?.signed?.overall_site_progress &&
                  currentSlide?.SaleStatus === "Signed") && (
                <Button
                  className="p-10 custom-neu-button-style"
                  onClick={handleJumpToSiteProgress}
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    height: "100%",
                  }}
                  text
                >
                  <ColumnFlex crossAxisAlignment="center">
                    <img src={WorkProgressIcon} alt="Work Progress Icon" />
                    <span className="fs-10 fw-600">Overall Site Progress</span>
                    <TextX
                      dataType="Date"
                      data={currentSlide?.SignProgressDate}
                      className="fs-8 fw-600"
                    />
                  </ColumnFlex>
                </Button>
              )}

              {currentSlide?.SaleStatus !== "Signed" &&
                currentSlide?.showLoanMenu &&
                subMenu?.booked?.loan && (
                  <Button
                    className={"p-10 custom-neu-button-style"}
                    onClick={handleJumpToLoanListing}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      height: "100%",
                    }}
                    text
                  >
                    <ColumnFlex crossAxisAlignment="center">
                      <img src={LoanIcon} />
                      <span className="fs-10 fw-600">Loan</span>
                      <TextX
                        dataType="Date"
                        data={currentSlide?.showLoanMenu?.apply_date}
                        className="fs-8 fw-600"
                      />
                    </ColumnFlex>
                  </Button>
                )}

              {/* Accounts Button */}
              {currentSlide?.SaleStatus === "Signed" &&
                currentSlide?.IsIntegrate &&
                subMenu?.signed?.accounts && (
                  <Button
                    className={"p-10 custom-neu-button-style"}
                    onClick={handleJumpToAccountState}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      height: "100%",
                    }}
                    text
                  >
                    <ColumnFlex crossAxisAlignment="center">
                      <img src={LoanIcon} />
                      <span className="fs-10 fw-600">Accounts</span>
                      <TextX
                        dataType="Number"
                        data={currentSlide?.BalanceAmt}
                        className="fs-8 fw-600"
                      />
                    </ColumnFlex>
                  </Button>
                )}

              {/* Key Collection */}
              {currentSlide?.SaleStatus === "Signed" &&
                currentSlide?.IsIntegrate &&
                currentSlide?.BalanceAmt === 0 &&
                currentSlide?.VPLetter &&
                subMenu?.signed?.key_collection && (
                  <Button
                    className={"p-10 custom-neu-button-style"}
                    onClick={() => setIsOpenKeyCollectionDialog(true)}
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      height: "100%",
                    }}
                    text
                  >
                    <ColumnFlex crossAxisAlignment="center">
                      <img src={KeyCollectionIcon} />
                      <span className="fs-10 fw-600">Key Collection</span>
                      <TextX
                        dataType="Date"
                        data={
                          latestKeyCollectionDate
                            ? latestKeyCollectionDate
                            : currentSlide?.KeyCollectionDate
                        }
                        className="fs-8 fw-600"
                      />
                    </ColumnFlex>
                  </Button>
                )}
            </RowGrid>
          </ContainerX>
        </ColumnFlex>
      </Card>
      {currentSlide?.SaleStatus === "Signed" &&
        currentSlide?.IsIntegrate &&
        currentSlide?.BalanceAmt === 0 &&
        currentSlide?.VPLetter && (
          <KeyCollectionDialog
            unitId={currentSlide?.UnitID}
            saleId={currentSlide?.SaleID}
            projectId={currentSlide?.ProjectID}
            visible={isOpenKeyCollectionDialog}
            onClose={() => setIsOpenKeyCollectionDialog(false)}
            onResetKeyCollectionDateTime={() => {
              setSelectedAvailableDate(null);
              setSelectedAvailableTime(null);
            }}
            keyCollectionEmailTemplate={keyCollectionEmailTemplate}
          />
        )}
    </>
  );
};

type UnitDetailsSliderProps = {
  salesAccount?: SalesAccountQuery;
  currentSalesId?: string;
  setCurrentSalesId?: (salesId: string) => void;
};

const UnitDetailsSlider: React.FC<UnitDetailsSliderProps> = ({
  salesAccount,
  currentSalesId,
  setCurrentSalesId,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const currentProjectId = searchParams.get("projectId");
  const navigation = useNavigate();
  const { accountCode } = useParams();
  const { textColor } = textChangeColor();

  useEffect(() => {
    const pathId = getSwiperSalesIdCookie();
    if (
      !pathId ||
      !salesAccount.getSalesAccount?.find((item) => item.SaleID === pathId)
    ) {
      setSwiperSalesIdCookie(salesAccount.getSalesAccount[0].SaleID);
    }
  }, []);

  const settings = {
    dots: true,
    infinite: salesAccount?.getSalesAccount?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,

    appendDots: (dots) => (
      <RowFlex fullWidth mainAxisAlignment="center" crossAxisAlignment="center">
        <ul
          id="dots"
          className={`custom-dots-container ${
            currentIndex + 1 >= 10 ? "overflow-active" : ""
          }`}
        >
          {dots}
        </ul>
      </RowFlex>
    ),
    afterChange: (current) => {
      setCurrentSalesId(salesAccount.getSalesAccount[current].SaleID);
      setCurrentIndex(current);
      setSwiperSalesIdCookie(salesAccount.getSalesAccount[current].SaleID);

      if (
        salesAccount.getSalesAccount[current].ProjectID !== currentProjectId
      ) {
        navigation(
          `${DASHBOARDS_PATH.DASHBOARD.replace(
            ":accountCode",
            accountCode
          )}?projectId=${salesAccount.getSalesAccount[current].ProjectID}`
        );
      }
    },
    initialSlide:
      getSwiperSalesIdCookie() &&
      salesAccount.getSalesAccount?.find(
        (item) => item.SaleID === getSwiperSalesIdCookie()
      )
        ? salesAccount.getSalesAccount.findIndex(
            (item) => item.SaleID === getSwiperSalesIdCookie()
          )
        : 0,
  };

  const handleJumpToNotification = () => {
    navigation(
      `${DASHBOARDS_PATH.NOTIFICATION.replace(
        ":accountCode",
        accountCode
      )}?projectId=${currentProjectId}`
    );
  };

  return (
    <Slider {...settings}>
      {salesAccount.getSalesAccount.map((item) => (
        <ContainerX
          key={item.SaleID}
          padding={{ only: { left: "16px", right: "16px", top: "14px" } }}
          className="color-text"
          color={textColor}
        >
          <ColumnFlex>
            <RowFlex mainAxisAlignment="space-between">
              <RowFlex gap="2px" crossAxisAlignment="center">
                <span className="fs-18 fw-700 text-with-shadow">
                  {item.UnitNo}
                </span>
                <StackX>
                  <IconButton rounded onClick={handleJumpToNotification}>
                    <NotificationsIcon
                      sx={{ width: "16px" }}
                      className="color-text-light-blue text-with-shadow"
                    />
                  </IconButton>
                  <StackItem right="2px" top="4px">
                    <div className="notify-bullet-unit">
                      {item.NotificationCount}
                    </div>
                  </StackItem>
                </StackX>
              </RowFlex>
              <RowFlex crossAxisAlignment="center" gap="3px">
                <MonetizationOnIcon
                  sx={{ width: "12px" }}
                  className="color-text-light-blue"
                />
                <TextX
                  data={item.InclusiveTax_SPA}
                  dataType="Number"
                  className="fs-12 fw-800 color-text-light-blue"
                  textStyle={{ color: textColor }}
                />
              </RowFlex>
            </RowFlex>
            <span className="fs-12 fw-600" style={{ color: textColor }}>
              {item.ProjectName}
            </span>
          </ColumnFlex>
        </ContainerX>
      ))}
    </Slider>
  );
};

export default UnitDetailsSwiper;
